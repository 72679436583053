import { destroyDictionary, fetchDictionaries } from '@/api/channel'
import { useNotifyErrors, useNotifySuccess, useTableOptions } from '@/composables'
import store from '@/store'
import { reactive, ref, watch } from '@vue/composition-api'
import { debounce } from 'lodash'

const cached = []

export default () => {
  const loading = ref(false)

  const tableColumns = [
    {
      text: 'ID',
      value: 'id',
    },
    {
      text: 'Type',
      value: 'category',
    },
    {
      text: 'Key',
      value: 'key',
    },
    {
      text: 'Value',
      value: 'value',
    },
    {
      text: 'Channel',
      value: 'channel_id',
    },
    {
      text: 'Notes',
      value: 'notes',
    },
    {
      text: 'ACTIONS',
      value: 'actions',
      align: 'center',
      sortable: false,
    },
  ]

  const tableItems = ref([])
  const tableTotal = ref(0)

  const options = ref(
    cached[0] || {
      sortBy: ['category'],
      sortDesc: [false],
      itemsPerPage: 15,
    },
  )

  // Filter
  const filters = reactive({
    name: '',
    rootOnly: true,
    channel_id: store.getters['channel/currentChannelId'],
  })

  const loadData = async () => {
    loading.value = true

    try {
      const { data } = await fetchDictionaries(
        useTableOptions(options.value, {
          ...parseFilters(),
        }),
      )

      if (data.status === 'success') {
        tableItems.value = data.data.records
        tableTotal.value = data.data.pagination.total
      }
    } catch (error) {
      console.log(error)
    } finally {
      loading.value = false
    }
  }

  const parseFilters = () =>
    Object.keys(filters)
      .filter(key => filters[key])
      .reduce(
        (a, key) => ({
          ...a,
          [key]: filters[key],
        }),
        {},
      )

  const deleteDictionary = obj =>
    destroyDictionary(obj.id)
      .then(() => {
        useNotifySuccess({
          content: 'Delete Success',
        })
        loadData()
      })
      .catch(useNotifyErrors)

  watch([options], () => {
    loadData()
  })

  watch(
    () => filters.name,
    debounce(() => {
      loadData()
    }, 800),
  )

  return {
    loading,

    tableColumns,
    tableItems,
    tableTotal,

    options,
    filters,

    loadData,
    deleteDictionary,
  }
}
