var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"site-list"}},[_c('v-row',{staticClass:"my-0 d-flex justify-end"},[_c('v-col',{staticClass:"d-flex",staticStyle:{"margin-right":"auto"},attrs:{"cols":"12","sm":"4","align":"right"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"color":"primary","loading":_vm.loading,"disabled":_vm.loading},on:{"click":_vm.loadData}},[_c('v-icon',{staticClass:"prefix-icon"},[_vm._v(" "+_vm._s(_vm.icons.mdiRefresh)+" ")]),_c('span',[_vm._v(" Refresh ")])],1)],1),_c('v-col',{attrs:{"cols":"12","sm":"4","align":"right"}},[_c('v-select',{attrs:{"label":"Show Channel","items":_vm.channelOptions,"item-text":"title","item-value":"value","outlined":"","clearable":""},model:{value:(_vm.filters.channel_id),callback:function ($$v) {_vm.$set(_vm.filters, "channel_id", $$v)},expression:"filters.channel_id"}})],1)],1),_c('v-card',[_c('v-data-table',{staticClass:"text-no-wrap",attrs:{"headers":_vm.tableColumns,"items":_vm.tableItems,"options":_vm.options,"server-items-length":_vm.tableTotal,"loading":_vm.loading,"header-props":_vm.headerprops},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" #"+_vm._s(item.id)+" ")]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(_vm.t(item.name)))])]}},{key:"item.value",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"text-wrap dict-value"},[_c('b',[_vm._v(_vm._s(_vm.t(item.value)))])]),_vm._l((item.children),function(item){return _c('div',{staticClass:"text-wrap dict-value ml-2"},[_c('b',[_vm._v(_vm._s(_vm.t(item.value)))]),_vm._v(" ("+_vm._s(item.distributor.slug)+") ")])})]}},{key:"item.channel_id",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-wrap"},[_vm._v(_vm._s(_vm.t(item.channel.name)))])]}},{key:"item.notes",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-wrap"},[_vm._v(_vm._s(item.notes))])]}},{key:"item.active",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-no-wrap"},[_vm._v(_vm._s(item.active ? 'Yes' : 'No'))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-menu',{attrs:{"bottom":"","left":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"small":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDotsVertical)+" ")])],1)]}}],null,true)},[_c('v-list',[_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.setAside(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencilOutline)+" ")]),_c('span',[_vm._v("Edit")])],1)],1),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.deleteDictionary(item)}}},[_c('v-list-item-title',[_c('v-icon',{staticClass:"me-2",attrs:{"size":"18"}},[_vm._v(" "+_vm._s(_vm.icons.mdiDeleteOutline)+" ")]),_c('span',[_vm._v("Delete")])],1)],1)],1)],1)],1)]}}],null,true)}),_c('div',{staticClass:"ml-4 mb-4"},[_vm._v(" Note: "),_c('div',[_vm._v("Only care about "),_c('b',[_vm._v("Updating Non-white label Channels. Such as PwC (api type)")]),_vm._v(".")]),_c('div',[_vm._v("Because white label channels will automatically sync their dictionaries to here.")])])],1),(_vm.aside)?_c('dictionary-aside',{attrs:{"resource":_vm.resource},on:{"changed":function($event){_vm.loadData(); _vm.aside = false}},model:{value:(_vm.aside),callback:function ($$v) {_vm.aside=$$v},expression:"aside"}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }